import { gql } from '@apollo/client';
import { RatificationResultFragment, RatificationResultVariableContributionFragment } from './fragments';
import { MoneyFields } from '../moneyFields';

export const RATIFICATION_RESULTS = gql`
  query ratificationResults($riskEvaluationId: Int!) {
    ratificationResults(riskEvaluationId: $riskEvaluationId) {
      ... RatificationResultFragment
      ratificationModel {
        id
        predictionType {
          id
          ratificationEnumIfHolds
        }
      }
      variablesContribution {
        ... RatificationResultVariableContributionFragment
      }
    }
  }
  ${RatificationResultVariableContributionFragment}
  ${RatificationResultFragment}
`;

export const RATIFICATION_VARIABLE_CONTRIBUTION = gql`
  query ratificationVariableContribution($riskEvaluationId: Int!) {
    ratificationVariableContribution(riskEvaluationId: $riskEvaluationId) {
      ... RatificationResultVariableContributionFragment
    }
  }
  ${RatificationResultVariableContributionFragment}
`;

export const MASTER_ENTITY_CREDIT_LINE = gql`
  query masterEntity(
    $masterEntityId: Int!
    $currency: String
  ) {
    getMasterEntity(
      masterEntityId: $masterEntityId
    ) {
      creditLine(currency: $currency) {
        id
        lastMovement {
          id
          totalCreditUsed {
            ...MoneyFields
          }
          amount {
            ...MoneyFields
          }
          createdAt
        }
        currentLimit {
          id
          createdAt
          newLimit {
            ...MoneyFields
          }
        }
        limitChanges {
          id
          createdAt
          newLimit {
            ...MoneyFields
          }
        }
        movements {
          totalCount
          totalPages
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            node {
              id
              createdAt
              amount {
                ...MoneyFields
              }
              totalCreditUsed {
                ...MoneyFields
              }
            }
          }
        }
        creditLineEvaluationRequestsLast {
          id
          createdAt
          updatedAt
          requestDate
          status
          reEvaluationDate
          resolvedDate
          approvedAmount {
            ... MoneyFields
          }
          user {
            id
            firstName
            lastName
          }
          comment
          creditlineevaluationvariablesSet {
            id
            debt {
              ... MoneyFields
            }
            avgMonthlyCessions {
              ... MoneyFields
            }
            avgInternalRate
            estimatedInterest {
              ... MoneyFields
            }
            rejectionsPercent
            fixedAssetsPurchasesLastYear {
              ... MoneyFields
            }
            fixedAssets {
              ... MoneyFields
            }
            ipc
            monetaryPolicyRate
            spread
            avgOperationTerm
            creditlineevaluationindicatorsSet {
              id
              createdAt
              legalRepresentativeDicom
              creditorsQuantity
              internalLeverage
              debtMultiplier
              customersConcentration
              dicomRate
              estimatedFlow {
                ... MoneyFields
              }
              bankCreditline {
                ... MoneyFields
              }
              flowCreditline {
                ... MoneyFields
              }
              profitCreditline {
                ... MoneyFields
              }
            }
          }
        }
      }
      receiverCessionsHistory {
        id
        cessionsNumber
        cessionsMoneyAmount
        historicCessionsNumber
        historicCessionsMoneyAmount
      }
      company {
        id
        cessionsHistory {
          id
          cessionsNumber
          cessionsMoneyAmount
          historicCessionsNumber
          historicCessionsMoneyAmount
        }
      }
    }
  }
  ${MoneyFields}
`;
